import { css } from '@emotion/core';
import { mq } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    top : '50%',
    transform : 'translateY(-50%)',
    lineHeight: '1.6rem',
    color: '#FFFFFF',
    fontSize: ['12px', null, '15px']
  })),
}