import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import { Item } from '../../items/item';
import  utils from '../../../utils';

export function MultiColumnPanel(props){
    const item = props.item;
    const mobileReverse = cmsUtils.payload(item, 'MobileReverse', 'no');
    //const subItems = item.items || [];
    const subItems = (mobileReverse === "yes" && utils.css.screenWidth() <= 550) ? item.items.reverse() || [] : item.items || [];
    const cmsOption = props.cmsOption || {};
    const columnWidths = cmsUtils.payload(item, 'ColumnWidths').split(",");
    const mobileColumnWidths = cmsUtils.payload(item, 'MobileColumnWidths').split(",");
    const countItem = {countD : 0, countM : 0, col : ""};

    const bgColor = cmsUtils.payload(item, 'BgColor');
    //const textColorTheme = cmsUtils.payload(item, 'TextColorTheme');
    const imageUrl = cmsUtils.payload(item, 'ImageUrl');
    const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
    const bgTheme = cmsUtils.payload(item, 'TextColorTheme') || 'white';
    const panelWidth = cmsUtils.payload(item, "PanelWidth");
    
    let bgUrl = null;
    if (imageUrl)
        bgUrl = utils.site.resourcePath(imageUrl);
    else if (backgroundTile)
        bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

    const cssClass =
    panelWidth === "full"
      ? utils.classNames(
          "cms_item",
          "contentPanel",
          "multiColumnPanel",
          "multiColumnPanelFull",
          "contentPanel--" + bgTheme,
          item.cssClass || item.anchorName || ""
        )
      : utils.classNames(
          "cms_item",
          "contentPanel",
          "multiColumnPanel",
          "contentPanel--" + bgTheme,
          item.cssClass || item.anchorName || ""
        );

    function setupClass(index){ 
        countItem.col = "";
        if(columnWidths.length === countItem.countD){
            countItem.countD = 0;
        }

        if(mobileColumnWidths.length === countItem.countM){
            countItem.countM = 0;
        }
        
        countItem.col = "col-sm" + (columnWidths[countItem.countD] === "13" ? "" : "-" + columnWidths[countItem.countD]) + " col-" + mobileColumnWidths[countItem.countM];
        countItem.countD  ++;
        countItem.countM  ++;
    };

    const subItemsElts = subItems.map((subItem, index) => {
            setupClass();
            return <div className = {countItem.col} key={subItem.itemId}>
                        <Item item={subItem} cmsOption={cmsOption}></Item>
                    </div> 
        }  
    );
    return (
        <section className={cssClass} style={{
            backgroundColor: `${bgColor}`,
            backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl)
            }} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div className="container">
              <div className = "row">
                {subItemsElts}
              </div> 
            </div>
        </section>
    );
}