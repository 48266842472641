import React from "react";
import { useRouter } from "../../components";
import { MdClose } from "react-icons/md";

export function SearchBar({ onCloseMenu, setSearchOpened }) {
  const [keyword, setKeyword] = React.useState("");
  const { navigate } = useRouter();
  function search(e) {
    e.preventDefault();
    onCloseMenu();
    navigate("/search-results?q=" + encodeURIComponent(keyword));
  }
  function onChange(e) {
    setKeyword(e.target.value);
  }
  return (
    <div className='searchBar3'>
      <form onSubmit={(e) => search(e)}>
        <input
          type='text'
          className='searchBar__input'
          placeholder='SEARCH'
          value={keyword}
          onChange={onChange}
        ></input>
      </form>
      <div className='searchBar3_icon' onClick={() => setSearchOpened(false)}>
        <MdClose size={22} />
      </div>
    </div>
  );
}